@font-face {
  font-family: "Causten Round";
  src: url(fonts/Causten-Round-Black.ttf);
  font-weight: 900;
}
@font-face {
  font-family: "Causten Round";
  src: url(fonts/Causten-Round-Extra-Bold.ttf);
  font-weight: 800;
}
@font-face {
  font-family: "Causten Round";
  src: url(fonts/Causten-Round-Semi-Bold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: "Causten Round";
  src: url(fonts/Causten-Round-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "Causten Round";
  src: url(fonts/Causten-Round-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: "Interphases Pro";
  src: url(fonts/interphasesPro/TT-Interphases-Pro-DemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: "Interphases Pro";
  src: url(fonts/interphasesPro/TT-Interphases-Pro-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: "Interphases Pro";
  src: url(fonts/interphasesPro/TT-Interphases-Pro-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "Tiempos Headline";
  src: url(fonts/TiemposText-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "ATB TT Norms";
  src: url(fonts/organizations/ATB/TT-Norms-TrueType/ATB-TT-Norms-Black.ttf);
  font-weight: 900;
}
@font-face {
  font-family: "ATB TT Norms";
  src: url(fonts/organizations/ATB/TT-Norms-TrueType/ATB-TT-Norms-ExtraBold.ttf);
  font-weight: 800;
}
@font-face {
  font-family: "ATB TT Norms";
  src: url(fonts/organizations/ATB/TT-Norms-TrueType/ATB-TT-Norms-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: "ATB TT Norms";
  src: url(fonts/organizations/ATB/TT-Norms-TrueType/ATB-TT-Norms-DemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: "ATB TT Norms";
  src: url(fonts/organizations/ATB/TT-Norms-TrueType/ATB-TT-Norms-Regular.ttf);
  font-weight: 500;
}
@font-face {
  font-family: "ATB TT Norms";
  src: url(fonts/organizations/ATB/TT-Norms-TrueType/ATB-TT-Norms-Normal.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "Inter";
  src: url(fonts/organizations/ATB/Inter/Inter.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "Poppins Black";
  src: url(fonts/organizations/CST/Poppins/Poppins-Black.ttf);
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Poppins Black Italic";
  src: url(fonts/organizations/CST/Poppins/Poppins-BlackItalic.ttf);
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Poppins Bold";
  src: url(fonts/organizations/CST/Poppins/Poppins-Bold.ttf);
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Poppins Bold Italic";
  src: url(fonts/organizations/CST/Poppins/Poppins-BoldItalic.ttf);
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Poppins ExtraBold";
  src: url(fonts/organizations/CST/Poppins/Poppins-ExtraBold.ttf);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Poppins ExtraBold Italic";
  src: url(fonts/organizations/CST/Poppins/Poppins-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Poppins ExtraLight";
  src: url(fonts/organizations/CST/Poppins/Poppins-ExtraLight.ttf);
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Poppins ExtraLight Italic";
  src: url(fonts/organizations/CST/Poppins/Poppins-ExtraLightItalic.ttf);
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Poppins Italic";
  src: url(fonts/organizations/CST/Poppins/Poppins-Italic.ttf);
  font-weight: 400; /* Typically regular weight */
  font-style: italic;
}

@font-face {
  font-family: "Poppins Light";
  src: url(fonts/organizations/CST/Poppins/Poppins-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins Light Italic";
  src: url(fonts/organizations/CST/Poppins/Poppins-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Poppins Medium";
  src: url(fonts/organizations/CST/Poppins/Poppins-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins Medium Italic";
  src: url(fonts/organizations/CST/Poppins/Poppins-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Poppins Regular";
  src: url(fonts/organizations/CST/Poppins/Poppins-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url(fonts/organizations/CST/Poppins/Poppins-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins SemiBold Italic";
  src: url(fonts/organizations/CST/Poppins/Poppins-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Poppins Thin";
  src: url(fonts/organizations/CST/Poppins/Poppins-Thin.ttf);
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Poppins Thin Italic";
  src: url(fonts/organizations/CST/Poppins/Poppins-ThinItalic.ttf);
  font-weight: 100;
  font-style: italic;
}

/* Montserrat Thin */
@font-face {
  font-family: "Montserrat Thin";
  src: url(fonts/Montserrat/Montserrat-Thin.ttf);
  font-weight: 100;
  font-style: normal;
}

/* Montserrat Thin Italic */
@font-face {
  font-family: "Montserrat Thin Italic";
  src: url(fonts/Montserrat/Montserrat-ThinItalic.ttf);
  font-weight: 100;
  font-style: italic;
}

/* Montserrat ExtraLight */
@font-face {
  font-family: "Montserrat ExtraLight";
  src: url(fonts/Montserrat/Montserrat-ExtraLight.ttf);
  font-weight: 200;
  font-style: normal;
}

/* Montserrat ExtraLight Italic */
@font-face {
  font-family: "Montserrat ExtraLight Italic";
  src: url(fonts/Montserrat/Montserrat-ExtraLightItalic.ttf);
  font-weight: 200;
  font-style: italic;
}

/* Montserrat Light */
@font-face {
  font-family: "Montserrat Light";
  src: url(fonts/Montserrat/Montserrat-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

/* Montserrat Light Italic */
@font-face {
  font-family: "Montserrat Light Italic";
  src: url(fonts/Montserrat/Montserrat-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

/* Montserrat Regular */
@font-face {
  font-family: "Montserrat Regular";
  src: url(fonts/Montserrat/Montserrat-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

/* Montserrat Italic */
@font-face {
  font-family: "Montserrat Italic";
  src: url(fonts/Montserrat/Montserrat-Italic.ttf);
  font-weight: 400;
  font-style: italic;
}

/* Montserrat Medium */
@font-face {
  font-family: "Montserrat Medium";
  src: url(fonts/Montserrat/Montserrat-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

/* Montserrat Medium Italic */
@font-face {
  font-family: "Montserrat Medium Italic";
  src: url(fonts/Montserrat/Montserrat-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
}

/* Montserrat SemiBold */
@font-face {
  font-family: "Montserrat SemiBold";
  src: url(fonts/Montserrat/Montserrat-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}

/* Montserrat SemiBold Italic */
@font-face {
  font-family: "Montserrat SemiBold Italic";
  src: url(fonts/Montserrat/Montserrat-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}

/* Montserrat Bold */
@font-face {
  font-family: "Montserrat Bold";
  src: url(fonts/Montserrat/Montserrat-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

/* Montserrat Bold Italic */
@font-face {
  font-family: "Montserrat Bold Italic";
  src: url(fonts/Montserrat/Montserrat-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

/* Montserrat ExtraBold */
@font-face {
  font-family: "Montserrat ExtraBold";
  src: url(fonts/Montserrat/Montserrat-ExtraBold.ttf);
  font-weight: 800;
  font-style: normal;
}

/* Montserrat ExtraBold Italic */
@font-face {
  font-family: "Montserrat ExtraBold Italic";
  src: url(fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

/* Montserrat Black */
@font-face {
  font-family: "Montserrat Black";
  src: url(fonts/Montserrat/Montserrat-Black.ttf);
  font-weight: 900;
  font-style: normal;
}

/* Montserrat Black Italic */
@font-face {
  font-family: "Montserrat Black Italic";
  src: url(fonts/Montserrat/Montserrat-BlackItalic.ttf);
  font-weight: 900;
  font-style: italic;
}

/* Montserrat Variable (normal) */
@font-face {
  font-family: "Montserrat Variable";
  src: url(fonts/Montserrat/Montserrat-VariableFont_wght.ttf);
  font-weight: 100 900;
  font-style: normal;
}

/* Montserrat Variable (italic) */
@font-face {
  font-family: "Montserrat Italic Variable";
  src: url(fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf);
  font-weight: 100 900;
  font-style: italic;
}

/* 9pt */

/* Newsreader 9pt Bold */
@font-face {
  font-family: "Newsreader 9pt Bold";
  src: url(fonts/Newsreader/Newsreader_9pt-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

/* Newsreader 9pt Bold Italic */
@font-face {
  font-family: "Newsreader 9pt Bold Italic";
  src: url(fonts/Newsreader/Newsreader_9pt-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

/* Newsreader 9pt ExtraBold */
@font-face {
  font-family: "Newsreader 9pt ExtraBold";
  src: url(fonts/Newsreader/Newsreader_9pt-ExtraBold.ttf);
  font-weight: 800;
  font-style: normal;
}

/* Newsreader 9pt ExtraBold Italic */
@font-face {
  font-family: "Newsreader 9pt ExtraBold Italic";
  src: url(fonts/Newsreader/Newsreader_9pt-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

/* Newsreader 9pt ExtraLight */
@font-face {
  font-family: "Newsreader 9pt ExtraLight";
  src: url(fonts/Newsreader/Newsreader_9pt-ExtraLight.ttf);
  font-weight: 200;
  font-style: normal;
}

/* Newsreader 9pt ExtraLight Italic */
@font-face {
  font-family: "Newsreader 9pt ExtraLight Italic";
  src: url(fonts/Newsreader/Newsreader_9pt-ExtraLightItalic.ttf);
  font-weight: 200;
  font-style: italic;
}

/* Newsreader 9pt Italic */
@font-face {
  font-family: "Newsreader 9pt Italic";
  src: url(fonts/Newsreader/Newsreader_9pt-Italic.ttf);
  font-weight: 400;
  font-style: italic;
}

/* Newsreader 9pt Light */
@font-face {
  font-family: "Newsreader 9pt Light";
  src: url(fonts/Newsreader/Newsreader_9pt-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

/* Newsreader 9pt Light Italic */
@font-face {
  font-family: "Newsreader 9pt Light Italic";
  src: url(fonts/Newsreader/Newsreader_9pt-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

/* Newsreader 9pt Medium */
@font-face {
  font-family: "Newsreader 9pt Medium";
  src: url(fonts/Newsreader/Newsreader_9pt-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

/* Newsreader 9pt Medium Italic */
@font-face {
  font-family: "Newsreader 9pt Medium Italic";
  src: url(fonts/Newsreader/Newsreader_9pt-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
}

/* Newsreader 9pt Regular */
@font-face {
  font-family: "Newsreader 9pt Regular";
  src: url(fonts/Newsreader/Newsreader_9pt-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

/* Newsreader 9pt SemiBold */
@font-face {
  font-family: "Newsreader 9pt SemiBold";
  src: url(fonts/Newsreader/Newsreader_9pt-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}

/* Newsreader 9pt SemiBold Italic */
@font-face {
  font-family: "Newsreader 9pt SemiBold Italic";
  src: url(fonts/Newsreader/Newsreader_9pt-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}


/* 14pt */

/* Newsreader 14pt Bold */
@font-face {
  font-family: "Newsreader 14pt Bold";
  src: url(fonts/Newsreader/Newsreader_14pt-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

/* Newsreader 14pt Bold Italic */
@font-face {
  font-family: "Newsreader 14pt Bold Italic";
  src: url(fonts/Newsreader/Newsreader_14pt-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

/* Newsreader 14pt ExtraBold */
@font-face {
  font-family: "Newsreader 14pt ExtraBold";
  src: url(fonts/Newsreader/Newsreader_14pt-ExtraBold.ttf);
  font-weight: 800;
  font-style: normal;
}

/* Newsreader 14pt ExtraBold Italic */
@font-face {
  font-family: "Newsreader 14pt ExtraBold Italic";
  src: url(fonts/Newsreader/Newsreader_14pt-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

/* Newsreader 14pt ExtraLight */
@font-face {
  font-family: "Newsreader 14pt ExtraLight";
  src: url(fonts/Newsreader/Newsreader_14pt-ExtraLight.ttf);
  font-weight: 200;
  font-style: normal;
}

/* Newsreader 14pt ExtraLight Italic */
@font-face {
  font-family: "Newsreader 14pt ExtraLight Italic";
  src: url(fonts/Newsreader/Newsreader_14pt-ExtraLightItalic.ttf);
  font-weight: 200;
  font-style: italic;
}

/* Newsreader 14pt Italic */
@font-face {
  font-family: "Newsreader 14pt Italic";
  src: url(fonts/Newsreader/Newsreader_14pt-Italic.ttf);
  font-weight: 400;
  font-style: italic;
}

/* Newsreader 14pt Light */
@font-face {
  font-family: "Newsreader 14pt Light";
  src: url(fonts/Newsreader/Newsreader_14pt-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

/* Newsreader 14pt Light Italic */
@font-face {
  font-family: "Newsreader 14pt Light Italic";
  src: url(fonts/Newsreader/Newsreader_14pt-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

/* Newsreader 14pt Medium */
@font-face {
  font-family: "Newsreader 14pt Medium";
  src: url(fonts/Newsreader/Newsreader_14pt-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

/* Newsreader 14pt Medium Italic */
@font-face {
  font-family: "Newsreader 14pt Medium Italic";
  src: url(fonts/Newsreader/Newsreader_14pt-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
}

/* Newsreader 14pt Regular */
@font-face {
  font-family: "Newsreader 14pt Regular";
  src: url(fonts/Newsreader/Newsreader_14pt-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

/* Newsreader 14pt SemiBold */
@font-face {
  font-family: "Newsreader 14pt SemiBold";
  src: url(fonts/Newsreader/Newsreader_14pt-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}

/* Newsreader 14pt SemiBold Italic */
@font-face {
  font-family: "Newsreader 14pt SemiBold Italic";
  src: url(fonts/Newsreader/Newsreader_14pt-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}


/* 24pt */

/* Newsreader 24pt Bold */
@font-face {
  font-family: "Newsreader 24pt Bold";
  src: url(fonts/Newsreader/Newsreader_24pt-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

/* Newsreader 24pt Bold Italic */
@font-face {
  font-family: "Newsreader 24pt Bold Italic";
  src: url(fonts/Newsreader/Newsreader_24pt-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

/* Newsreader 24pt ExtraBold */
@font-face {
  font-family: "Newsreader 24pt ExtraBold";
  src: url(fonts/Newsreader/Newsreader_24pt-ExtraBold.ttf);
  font-weight: 800;
  font-style: normal;
}

/* Newsreader 24pt ExtraBold Italic */
@font-face {
  font-family: "Newsreader 24pt ExtraBold Italic";
  src: url(fonts/Newsreader/Newsreader_24pt-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

/* Newsreader 24pt ExtraLight */
@font-face {
  font-family: "Newsreader 24pt ExtraLight";
  src: url(fonts/Newsreader/Newsreader_24pt-ExtraLight.ttf);
  font-weight: 200;
  font-style: normal;
}

/* Newsreader 24pt ExtraLight Italic */
@font-face {
  font-family: "Newsreader 24pt ExtraLight Italic";
  src: url(fonts/Newsreader/Newsreader_24pt-ExtraLightItalic.ttf);
  font-weight: 200;
  font-style: italic;
}

/* Newsreader 24pt Italic */
@font-face {
  font-family: "Newsreader 24pt Italic";
  src: url(fonts/Newsreader/Newsreader_24pt-Italic.ttf);
  font-weight: 400;
  font-style: italic;
}

/* Newsreader 24pt Light */
@font-face {
  font-family: "Newsreader 24pt Light";
  src: url(fonts/Newsreader/Newsreader_24pt-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

/* Newsreader 24pt Light Italic */
@font-face {
  font-family: "Newsreader 24pt Light Italic";
  src: url(fonts/Newsreader/Newsreader_24pt-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

/* Newsreader 24pt Medium */
@font-face {
  font-family: "Newsreader 24pt Medium";
  src: url(fonts/Newsreader/Newsreader_24pt-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

/* Newsreader 24pt Medium Italic */
@font-face {
  font-family: "Newsreader 24pt Medium Italic";
  src: url(fonts/Newsreader/Newsreader_24pt-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
}

/* Newsreader 24pt Regular */
@font-face {
  font-family: "Newsreader 24pt Regular";
  src: url(fonts/Newsreader/Newsreader_24pt-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

/* Newsreader 24pt SemiBold */
@font-face {
  font-family: "Newsreader 24pt SemiBold";
  src: url(fonts/Newsreader/Newsreader_24pt-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}

/* Newsreader 24pt SemiBold Italic */
@font-face {
  font-family: "Newsreader 24pt SemiBold Italic";
  src: url(fonts/Newsreader/Newsreader_24pt-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}


/* 36pt */

/* Newsreader 36pt Bold */
@font-face {
  font-family: "Newsreader 36pt Bold";
  src: url(fonts/Newsreader/Newsreader_36pt-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

/* Newsreader 36pt Bold Italic */
@font-face {
  font-family: "Newsreader 36pt Bold Italic";
  src: url(fonts/Newsreader/Newsreader_36pt-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

/* Newsreader 36pt ExtraBold */
@font-face {
  font-family: "Newsreader 36pt ExtraBold";
  src: url(fonts/Newsreader/Newsreader_36pt-ExtraBold.ttf);
  font-weight: 800;
  font-style: normal;
}

/* Newsreader 36pt ExtraBold Italic */
@font-face {
  font-family: "Newsreader 36pt ExtraBold Italic";
  src: url(fonts/Newsreader/Newsreader_36pt-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

/* Newsreader 36pt ExtraLight */
@font-face {
  font-family: "Newsreader 36pt ExtraLight";
  src: url(fonts/Newsreader/Newsreader_36pt-ExtraLight.ttf);
  font-weight: 200;
  font-style: normal;
}

/* Newsreader 36pt ExtraLight Italic */
@font-face {
  font-family: "Newsreader 36pt ExtraLight Italic";
  src: url(fonts/Newsreader/Newsreader_36pt-ExtraLightItalic.ttf);
  font-weight: 200;
  font-style: italic;
}

/* Newsreader 36pt Italic */
@font-face {
  font-family: "Newsreader 36pt Italic";
  src: url(fonts/Newsreader/Newsreader_36pt-Italic.ttf);
  font-weight: 400;
  font-style: italic;
}

/* Newsreader 36pt Light */
@font-face {
  font-family: "Newsreader 36pt Light";
  src: url(fonts/Newsreader/Newsreader_36pt-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

/* Newsreader 36pt Light Italic */
@font-face {
  font-family: "Newsreader 36pt Light Italic";
  src: url(fonts/Newsreader/Newsreader_36pt-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

/* Newsreader 36pt Medium */
@font-face {
  font-family: "Newsreader 36pt Medium";
  src: url(fonts/Newsreader/Newsreader_36pt-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

/* Newsreader 36pt Medium Italic */
@font-face {
  font-family: "Newsreader 36pt Medium Italic";
  src: url(fonts/Newsreader/Newsreader_36pt-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
}

/* Newsreader 36pt Regular */
@font-face {
  font-family: "Newsreader 36pt Regular";
  src: url(fonts/Newsreader/Newsreader_36pt-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

/* Newsreader 36pt SemiBold */
@font-face {
  font-family: "Newsreader 36pt SemiBold";
  src: url(fonts/Newsreader/Newsreader_36pt-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}

/* Newsreader 36pt SemiBold Italic */
@font-face {
  font-family: "Newsreader 36pt SemiBold Italic";
  src: url(fonts/Newsreader/Newsreader_36pt-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}


/* 60pt */

/* Newsreader 60pt Bold */
@font-face {
  font-family: "Newsreader 60pt Bold";
  src: url(fonts/Newsreader/Newsreader_60pt-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

/* Newsreader 60pt Bold Italic */
@font-face {
  font-family: "Newsreader 60pt Bold Italic";
  src: url(fonts/Newsreader/Newsreader_60pt-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

/* Newsreader 60pt ExtraBold */
@font-face {
  font-family: "Newsreader 60pt ExtraBold";
  src: url(fonts/Newsreader/Newsreader_60pt-ExtraBold.ttf);
  font-weight: 800;
  font-style: normal;
}

/* Newsreader 60pt ExtraBold Italic */
@font-face {
  font-family: "Newsreader 60pt ExtraBold Italic";
  src: url(fonts/Newsreader/Newsreader_60pt-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

/* Newsreader 60pt ExtraLight */
@font-face {
  font-family: "Newsreader 60pt ExtraLight";
  src: url(fonts/Newsreader/Newsreader_60pt-ExtraLight.ttf);
  font-weight: 200;
  font-style: normal;
}

/* Newsreader 60pt ExtraLight Italic */
@font-face {
  font-family: "Newsreader 60pt ExtraLight Italic";
  src: url(fonts/Newsreader/Newsreader_60pt-ExtraLightItalic.ttf);
  font-weight: 200;
  font-style: italic;
}

/* Newsreader 60pt Italic */
@font-face {
  font-family: "Newsreader 60pt Italic";
  src: url(fonts/Newsreader/Newsreader_60pt-Italic.ttf);
  font-weight: 400;
  font-style: italic;
}

/* Newsreader 60pt Light */
@font-face {
  font-family: "Newsreader 60pt Light";
  src: url(fonts/Newsreader/Newsreader_60pt-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

/* Newsreader 60pt Light Italic */
@font-face {
  font-family: "Newsreader 60pt Light Italic";
  src: url(fonts/Newsreader/Newsreader_60pt-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

/* Newsreader 60pt Medium */
@font-face {
  font-family: "Newsreader 60pt Medium";
  src: url(fonts/Newsreader/Newsreader_60pt-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

/* Newsreader 60pt Medium Italic */
@font-face {
  font-family: "Newsreader 60pt Medium Italic";
  src: url(fonts/Newsreader/Newsreader_60pt-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
}

/* Newsreader 60pt Regular */
@font-face {
  font-family: "Newsreader 60pt Regular";
  src: url(fonts/Newsreader/Newsreader_60pt-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

/* Newsreader 60pt SemiBold */
@font-face {
  font-family: "Newsreader 60pt SemiBold";
  src: url(fonts/Newsreader/Newsreader_60pt-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}

/* Newsreader 60pt SemiBold Italic */
@font-face {
  font-family: "Newsreader 60pt SemiBold Italic";
  src: url(fonts/Newsreader/Newsreader_60pt-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}


/* Variable Fonts */

/* Newsreader Variable (normal) */
@font-face {
  font-family: "Newsreader Variable";
  src: url(fonts/Newsreader/Newsreader-VariableFont_opsz,wght.ttf);
  font-weight: 100 900;
  font-style: normal;
}

/* Newsreader Variable (italic) */
@font-face {
  font-family: "Newsreader Italic Variable";
  src: url(fonts/Newsreader/Newsreader-Italic-VariableFont_opsz,wght.ttf);
  font-weight: 100 900;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(fonts/demo/proximanova/proximanova-regular.otf) format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(fonts/demo/proximanova/proximanova-semibold.otf) format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(fonts/demo/proximanova/proximanova-bold.otf) format('opentype');
  font-weight: 700;
  font-style: normal;
}
